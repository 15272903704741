
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

  import { Auto } from '@/entities/public'
  import Panels from '../panels'
  import { Query } from '@/entities/public/Resource/interfaces'
  import { formFilter } from '@/graphql/generated-types'
  import { mapActions } from 'vuex'

@Component({
  components: {
    ...Panels,
  },
  methods: {
    ...mapActions('resources/form', ['fetchData']),
  },
})
  export default class AutoContent extends Vue {
  @Prop({ type: Object, required: true }) auto!: Auto;
  @Prop({ type: Boolean, default: true }) detailed?: boolean;

  alternativeAttributes = []
  inspectionAttributes = []
  color = null

  fetchData!: (payload: {
    query: Query
    filter?: formFilter
    offset?: number
    limit?: number
    force?: boolean
    distinct?: Array<string>
  }) => Promise<any>;

  get attributes () {
    const { auto } = this

    this.alternativeAttributes = []

    this.color = auto?.dealAutoAttribute?.find(component => component.slug === 'color')
    if (!auto.generation?.attributes?.length) {
      this.findAlternativesAttributes(auto)
    }
    return auto?.generation?.attributes
  }

  get favorites () {
    const { attributes, alternativeAttributes, inspectionAttributes } = this

    const otherInfo = ['color']

    if (attributes?.length) {
      const attrs = [attributes, inspectionAttributes].flat()
      const favorites = attrs?.filter(attr => attr?.base?.favorite || otherInfo.includes(attr?.slug)) || []
      if (!favorites.length) return []
      favorites.push(this.color)
      return this.filterUniqSlug(favorites?.filter(Boolean))
    }

    const attrs = [alternativeAttributes, inspectionAttributes].flat()
    const favoritesAlternative = attrs?.filter(attr => attr?.base?.favorite || otherInfo.includes(attr?.slug)) || []
    if (!favoritesAlternative.length) return []
    favoritesAlternative.push(this.color)
    return this.filterUniqSlug(favoritesAlternative.filter(Boolean))
  }

  filterUniqSlug (arr) {
    return arr.filter((item, index, self) =>
        index === self.findIndex(t => (
          t.slug === item.slug
        ))
    )
  }

  get groups () {
    const { detailed } = this
    if (!detailed) return undefined

    const { attributes, alternativeAttributes, inspectionAttributes } = this

    const attrs = attributes?.length ? attributes : alternativeAttributes

    const groups = this.getGroupsFromAttributes(attrs, inspectionAttributes)

    return Object.entries(groups).map(([title, { icon, regular, extra }]) => ({
      icon,
      title,
      regular,
      extra,
    }))
  }

  async findAlternativesAttributes (auto) {
    if (!auto?.version?.version?.id || !auto?.version?.year?.id) return

    let getGenerations = await this.getGeneration(auto?.version?.version?.id, auto?.version?.year?.id)

    let generation = getGenerations?.length === 1 ? getGenerations[0] : getGenerations.find(generation => generation.id === auto.generation?.id)

    if (generation?.attributes?.length) {
      this.alternativeAttributes = generation?.attributes
      return
    }

    const attributes = await this.fetchData({
      query: {
        name: 'find',
        model: 'Attribute',
      },
      filter: {
        id_version_year: { _eq: auto.version.id },
      },
    })

    if (attributes?.length) {
      this.alternativeAttributes = attributes
      return
    }

    if (!auto?.generation?.sku) {
      return
    }

    let cont = 1
    while (cont <= 3 && !generation?.attributes?.length) {
      getGenerations = await this.getGeneration(auto?.version?.version?.id, auto?.version?.year?.id - cont)

      generation = getGenerations?.length === 1 ? getGenerations[0] : getGenerations.find(generation => generation.sku === auto.generation?.sku)

      if (generation?.attributes?.length) {
        this.alternativeAttributes = generation?.attributes || []
        break
      } else {
        cont++
      }
    }

    this.alternativeAttributes = generation?.attributes || []
  }

  async getGeneration (version, year) {
    return this.fetchData({
      query: { name: 'find', model: 'Generation' },
      filter: { version_year: { id_version: { _eq: version }, id_year: { _eq: year } } },
    })
  }

  getGroupsFromAttributes (
    attributes: Array<{ base: { multiple: boolean; name: string; icon: string }; val: string }>,
    otherAttributes: Array<{ slug: string; name: string; val: string }>
  ): Record<string, { icon: string, regular: Array<any>, extra: Array<any> }> {
    const groups: Record<string, { icon: string, regular: Array<any>, extra: Array<any> }> = {}

    // Procesar atributos con base múltiple
    attributes?.filter(({ base }) => base.multiple).forEach(attr => {
      const { base: { name, icon } } = attr
      const cleanedName = this.capitalizeName(name.replace('Equipamiento ', ''))

      if (!groups[cleanedName]) {
        groups[cleanedName] = { icon, regular: [attr], extra: [] }
        return
      }

      groups[cleanedName].regular.push(attr)
    })

    // Procesar atributos en otherAttributes (todos son "Extra")
    otherAttributes?.forEach(attr => {
      const equipmentGroup = attr?.slug.includes('equipment')
        ? this.capitalizeName(attr?.name.replace('Equipamiento ', ''))
        : null

      if (equipmentGroup) {
        if (!groups[equipmentGroup]) {
          groups[equipmentGroup] = { icon: 'mdi-tools', regular: [], extra: [] }
        }

        // Agregar el atributo como "Extra"
        const extraAttr = {
          ...attr,
          val: this.capitalizeName(attr.val.replace('Equipamiento ', '')),
        }

        // Evitar duplicados entre regular y extra
        if (!groups[equipmentGroup].regular.some(item => item.val === extraAttr.val)) {
          groups[equipmentGroup].extra.push(extraAttr)
        }
      }
    })

    // Ordenar atributos dentro de cada grupo
    for (const groupName in groups) {
      groups[groupName].regular.sort((a, b) =>
        a.val.toLowerCase().localeCompare(b.val.toLowerCase())
      )
      groups[groupName].extra.sort((a, b) =>
        a.val.toLowerCase().localeCompare(b.val.toLowerCase())
      )
    }

    return groups
  }

// Método auxiliar para capitalizar nombres
  capitalizeName (name: string): string {
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
  }

  @Watch('auto', { immediate: true, deep: true })
  async onAutoChange (val: Auto) {
    if (!val?.id) return
    const attrs = await this.fetchData({
      query: { name: 'find', model: 'DealAutoAttribute' },
      filter: { deal: { id_auto: { _eq: val.id } } },
      force: true,
    })

    if (attrs?.length) {
      const components = ['owners_number', 'mileage']
      this.inspectionAttributes = attrs.filter(({ component }) => !components?.includes(component.slug))
    }
  }
  }
