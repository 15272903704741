import { ComputedQuery } from '@/entities/public/Resource/interfaces/form'
import { deepEqual, getObjectValueByPath } from '@/utils/vuetify/helpers'
import { formFilter } from '@/graphql/generated-types'
import { deepCopy } from '@/utils/general'

export class Query {
  public search: string
  public update: () => Promise<void>

  readonly uid: string;
  readonly params?: any
  readonly distinct?: Array<string>
  readonly triggers?: Array<string>;
  readonly variables: Record<string, any>
  filter?: any

  private readonly fetch: (query: Query) => Promise<any>
  private _cache: { filter: any, result: any }

  constructor (definition: ComputedQuery, value, fetch) {
    const { uid, triggers, where, variables = [], params, distinct } = definition

    this.uid = uid
    this.triggers = triggers
    this.params = params
    this.distinct = distinct

    const search = () => this.search
    this.variables = variables.reduce(
      (result, { name, path }) =>
        Object.defineProperty(result, name, {
          get () {
            return getObjectValueByPath(value, path)
          },
          enumerable: true,
        }),
      Object.defineProperty({}, '@', {
        get () {
          return `%${search() || ''}%`
        },
      }))

    this.buildFilter(where)
    this.fetch = fetch
  }

  get filled () {
    const { variables } = this
    return !Object.entries(variables).some(([key, value]) => typeof value === 'undefined')
  }

  get cache () {
    const { _cache } = this
    if (!_cache) return undefined

    const { filter } = this
    if (!deepEqual(filter, _cache.filter)) return this._cache = undefined

    return _cache.result
  }

  async value (): Promise<any> {
    const { filled, search } = this
    if (!filled && !search) return

    const { filter } = this
    this._cache = {
      filter: filter && deepCopy(filter),
      result: await this.fetch(this),
    }

    return this._cache.result
  }

  private buildFilter (where: formFilter) {
    if (!where) return

    this.filter = deepCopy(where)
    const { variables, filter } = this
    const search = ([key, value], parent) => {
      if (typeof value !== 'object') {
        if (value in variables) {
          Object.defineProperty(parent, key, {
            get () {
              return variables[value]
            },
            enumerable: true,
          })
        }
        return
      }

      const deepSearch = item => Object.entries(item).map(entry => search(entry, item))
      if (Array.isArray(value)) return value.forEach(deepSearch)
      return deepSearch(value)
    }

    Object.entries(filter).forEach(entry => search(entry, filter))
  }
}
