import { Condition } from './condition'
import { Statement } from './statement'
import { Tree } from './tree'

// type CustomOperator = string
export type BaseOperator = '_eq' | '_gt' | '_gte' | '_in' | '_lt' | '_is_null' | '_lte' | '_neq' | '_nin'
export type Value = string | boolean | number | undefined | null

export enum Operator {
  and = '_and',
  or = '_or',
  not = '_not'
}

export interface ConditionalStatement {
  path: string
  operator: BaseOperator
  value: Value | Array<Value>
  paths?: Array<string>
}

export interface ConditionalAndTree {
  [Operator.and]: Array<ICondition>
}

export interface ConditionalOrTree {
  [Operator.or]: Array<ICondition>
}

export interface ConditionalNotTree {
  [Operator.not]: ICondition
}

export type ICondition = ConditionalAndTree | ConditionalOrTree | ConditionalNotTree | ConditionalStatement

export function buildCondition (object: any, condition: ICondition): Condition {
  const operator = [Operator.and, Operator.or, Operator.not].find(operator => operator in condition)
  if (!operator) return new Statement(object, condition as ConditionalStatement)
  const conditions = operator === Operator.not ? [condition[operator]] : condition[operator]
  return new Tree(object, operator, conditions.map(_ => buildCondition(object, _)))
}

export * from './condition'
