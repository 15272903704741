import { Condition } from './condition'
import { Operator } from '.'

export class Tree extends Condition {
  type: Operator
  conditions: Array<Condition>

  constructor (object: any, type: Operator, conditions: Array<Condition>) {
    super()
    this.type = type
    this.conditions = conditions
  }

  get result (): boolean {
    const { type, conditions } = this

    switch (type) {
      case Operator.and:
        return conditions.every(({ result }) => result)
      case Operator.or:
        return conditions.some(({ result }) => result)
      case Operator.not:
        return !conditions[0].result
    }
  }

  get paths (): Array<string> {
    const { conditions } = this
    return conditions.map(({ paths }) => paths).flat()
  }
}
