
  import { Component, Prop, Vue } from 'vue-property-decorator'

  import { BaseRow } from './interfaces'

  import { Icon } from '@/entities/settings'
  import { getObjectValueByPath, setValueByPath } from '@/utils/vuetify/helpers'
  import { fixPrice } from '@/utils/general'

@Component({ methods: { fixPrice } })
  export default class Row extends Vue implements BaseRow {
  @Prop({ type: [String, Number, Object, Array] }) value!: any;
  @Prop({ type: String }) label!: string;
  @Prop({ type: String }) itemText!: string;
  @Prop({ type: String, default: null }) position!: string;
  @Prop({ type: String }) textColor!: string;
  @Prop({ type: String }) formatter!: string;
  @Prop({ type: String }) tooltip?: string;
  @Prop({ type: [String, Object] }) icon?: string | Icon;
  @Prop({ type: String }) color?: string;
  @Prop({ type: Boolean, default: undefined }) container!: boolean;
  @Prop({ type: Boolean, default: undefined }) outlined!: boolean;
  @Prop({ type: Boolean, default: false }) noBorder!: boolean;

  @Prop({ type: Object }) queries?: Record<string, Promise<any>>

  loadQueries () {
    // This is just an example usage.
    const { queries } = this
    if (!queries) return

    return Promise.all(Object.entries(queries).map(async ([path, value]) => setValueByPath(this, path, await value)))
  }

  format (value) {
    const { formatter } = this
    if (!formatter) return value

    const format = this[formatter] as Function
    if (!format || typeof format !== 'function') throw new Error(`Unimplemented formatter ${formatter}`)

    return format(value)
  }

  get text () {
    const { value, itemText } = this
    return this.format(itemText ? getObjectValueByPath(value, itemText) : value)
  }

  get bind () {
    const { value, label, itemText, tooltip, icon, color, container, extendBind } = this

    return {
      value,
      label,
      itemText,
      tooltip,
      icon,
      color,
      container,
      ...extendBind,
    }
  }

  get extendBind () {
    return {}
  }
  }
