import { BaseOperator, ConditionalStatement, Value } from '.'
import { Condition } from './condition'
import { getObjectValueByPath } from '@/utils/vuetify/helpers'

const operators: Record<BaseOperator, (a, b) => boolean> = {
  _is_null: (a: Value, b: Value) => (a === undefined || a === null) === b,
  _eq: (a: Value, b: Value) => a === b,
  _neq: (a: Value, b: Value) => !operators._eq(a, b),
  _in: (a: Value, b: Array<Value>) => b.includes(a),
  _nin: (a: Value, b: Value) => !operators._in(a, b),
  _lt: (a: Value, b: Value) => a < b,
  _lte: (a: Value, b: Value) => a <= b,
  _gt: (a: Value, b: Value) => a > b,
  _gte: (a: Value, b: Value) => a >= b,
}

export class Statement extends Condition {
  private readonly operator: BaseOperator;
  private readonly value: Value | Array<Value>;
  private readonly path: string
  private readonly obj: any

  constructor (object: any, { path, value, operator }: ConditionalStatement) {
    super()
    this.operator = operator
    this.value = value
    this.path = path
    this.obj = object
  }

  private get target () {
    const { path, obj } = this
    return this._target = getObjectValueByPath(obj, path)
  }

  get result (): boolean {
    const { operator } = this

    const operation = operators[operator]
    if (!operation) throw new Error(`The "${operator}" is not valid`)

    const { target, value } = this
    return operation(target, value)
  }

  get paths (): Array<string> {
    return [this.path]
  }
}
